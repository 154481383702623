<template>
  <div class="mainBox page-container">
    <Breadcrumb></Breadcrumb>

    <!--  操作区-->
    <el-card>
      <el-form
        :inline="true"
        :model="selectInfo"
        class="demo-form-inline"
        size="small"
        ref="selectOptionsFormRef"
      >
        <el-form-item label="患者姓名" prop="keyword">
          <el-input
            v-model="selectInfo.keyword"
            placeholder="请输入患者姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="医院名称" prop="hospitalId">
          <el-select
            v-model="selectInfo.hospitalId"
            placeholder="选择医院"
            filterable
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="(item, index) in hospitalList"
              :key="index"
              :label="item.hospitalName"
              :value="item.hospitalId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="医生工作室" prop="groupId">
          <el-select
            v-model="selectInfo.groupId"
            placeholder="选择工作室"
            filterable
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="(item, index) in doctorOfficeList"
              :key="index"
              :label="item.groupName"
              :value="item.groupId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联医生" prop="assistantId">
          <el-select
            v-model="selectInfo.assistantId"
            placeholder="选择关联医生"
            filterable
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="(item, index) in assistantList"
              :key="index"
              :label="item.assistantName"
              :value="item.assistantId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="患者类型" prop="patientStatus">
          <el-select v-model="selectInfo.patientStatus" placeholder="请选择">
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="item in patientStatusList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="resetSelectOptions"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <div class="formregion">
      <el-table :data="patientList" border size="mini" style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="患者姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phoneNo"
          label="手机号码"
          width="140"
          align="center"
        ></el-table-column>
        <el-table-column prop="hospitalName" label="医院名称" align="center">
          <template slot-scope="socpe">
            <span v-if="socpe.row.hospitalName == null">-</span>
            <span v-else>{{ socpe.row.hospitalName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="groupName" label="医生工作室" align="center">
          <template slot-scope="socpe">
            <span v-if="socpe.row.groupName == null">-</span>
            <span v-else>{{ socpe.row.groupName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="assistantName"
          label="关联医生"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sellerName"
          label="关联健康顾问"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="patientStatus"
          label="患者类型"
          width="140"
          align="center"
        >
          <template slot-scope="socpe">
            <span>{{
              socpe.row.patientStatus === 1 ? "精准管理患者" : "自主管理患者"
            }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pag_warpper">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagenum"
          :page-sizes="[10, 20, 50]"
          :page-size="pagesize"
          layout="slot, sizes, prev, pager, next, jumper"
          :total="total"
          style="margin-left: 30px"
        >
          <span class="total"
            >共 {{ total }} 条数据，共
            {{ Math.ceil(total / pagesize) }} 页</span
          >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { timeMode } from "../../tools/utils";
import { selectparams } from "../../mixins/selectparams";
import { callpatients } from "../../mixins/callpatients";
export default {
  name: "patientlist",
  mixins: [selectparams, callpatients],
  data() {
    return {
      timeMode,
      //查询数据
      selectInfo: {
        keyword: "",
        groupId: "",
        hospitalId: "",
        assistantId: "",
        patientStatus: "",
      },
      doctorOfficeList: [],
      assistantList: [],
      hospitalList: [],
      pagenum: 1,
      pagesize: 10,
      total: 0,
      patientList: [],
      patientStatusList: [
        {
          name: "精准管理",
          value: 1,
        },
        {
          name: "自主管理",
          value: 0,
        },
      ],
    };
  },
  created() {
    //获取所有工作室列表
    this.getPatientsList(this.selectInfo, this.pagenum, this.pagesize);
  },
  //总条数
  computed: {},
  mounted() {},
  methods: {
    //重置筛选项
    resetSelectOptions() {
      this.$refs.selectOptionsFormRef.resetFields();
      console.log(this.selectInfo);
      this.getPatientsList(this.selectInfo, 1, this.pagesize);
    },
    //查询按钮
    async onSubmit() {
      this.getPatientsList(this.selectInfo, 1, this.pagesize);
    },
    handleSizeChange(val) {
      console.log(val);
      this.pagesize = val;
      this.getPatientsList(this.selectInfo, this.pagenum, this.pagesize);
    },
    handleCurrentChange(val) {
      console.log(val);
      this.pagenum = val;
      this.getPatientsList(this.selectInfo, this.pagenum, this.pagesize);
    },

    //获取患者数据
    getPatientsList(selectInfo, page, pageSize) {
      let _params = {
        ...selectInfo,
        page,
        pageSize,
      };
      this.$http.getAllPatient(_params).then((res) => {
        console.log(res);
        this.pagesize = res.result.data.rows;
        this.total = res.result.data.records;
        this.pagenum = res.result.data.page;
        this.patientList = res.result.data.data;
      });
    },
  },
};
</script>

<style scoped lang="less">
.mainBox {
  display: flex;
  flex-direction: column;
  /*height: 100%*/
}
/*.el-form {*/
/*  margin-left: 20px;*/
/*}*/
.el-form-item {
  margin-right: 30px;
  .el-button {
    width: 80px;
  }
}
/*.el-tabs {*/
/*  margin-left: 30px;*/
/*}*/
.el-tab-pane {
  max-height: 560px;
  overflow-y: auto;
}
.formregion {
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 10px 10px 10px 0;
  flex: 1;
  padding: 0 20px;
}
.pag_warpper {
  background-color: #ffffff;
  height: 80px;
  padding: 20px;
  border-radius: 0 0 0 10px;
}
.el-card {
  flex-shrink: 0;
}
.total {
  margin-right: 24px;
  font-weight: 400 !important;
  color: #606266;
}
/deep/.callAndUnbundleBox {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 30px;
    box-sizing: border-box;
    .callAndUnbundleCon {
      .title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #606266;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 8px;
        span {
          color: #409eff;
        }
      }
      .associatedBtn {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        .associatedCancel {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #303133;
          width: 120px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #dcdfe6;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .associatedSure {
          width: 120px;
          height: 40px;
          background: #4facff;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
